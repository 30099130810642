import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'

const BASE_API = process.env.BASE_API
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class SignUpDetailsPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
    this.resendemail = this.resendemail.bind(this)
    this.registeruser = this.registeruser.bind(this)
  }
  resendemail = async (event) => {
    console.log(this.props)
  }
  registeruser = async (event) => {
    
    var newuserdetails = {
      firstName: this.props.allCookies.userdetails.firstName,
      lastName: this.props.allCookies.userdetails.lastName,
      email: this.props.allCookies.userdetails.email,
      token: this.props.allCookies.userdetails.token,
      allowExtraEmails: this.props.allCookies.userdetails.allowExtraEmails,
      termsandconditions: this.props.allCookies.userdetails.termsandconditions,
      subscriptionid: this.props.allCookies.userdetails.planDetails.id,
      subscriptiontype: this.props.allCookies.userdetails.subscriptiontype,
      planDetails: this.props.allCookies.userdetails.planDetails,
      promocodeused:this.props.allCookies.userdetails.promocodeapplied  
    }
    // console.log(JSON.stringify(newuserdetails))
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(newuserdetails),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    fetch(BASE_API + 'usermgmt_verifyemail', requestConfig)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        console.log(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }

  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    if (this.props.allCookies.userdetails) {
      this.registeruser()
    } else {
      console.log('There was an error with the information')
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Sign Up Success'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var signupdetails = (
      <div>
        <p>
          Congratulations on subscribing to an EdgeForecaster plan. You will be
          sent an email shortly with a link to verify your email address and
          activate your account. Once you have done this, click on the{' '}
          <span className="spanhighlight">Sign In</span> button on the menu to
          log into your profile.
        </p>
        <p>
          If you don’t receive an email, please click{' '}
          <span className="spanhighlight point" onClick={this.resendemail}>
            here
          </span>{' '}
          to resend it.
        </p>
      </div>
    )
    if (pagewidth.matches) {
      if (this.props.allCookies.planDetails.pricedetails) {
        var planname = ''
        Object.keys(this.props.allCookies.planDetails.pricedetails).map(
          (key) => {
            if (
              this.props.allCookies.planDetails.pricedetails[key].id ===
              this.props.allCookies.subscriptionid
            ) {
              planname = this.props.allCookies.planDetails.pricedetails[key]
                .product.name
            }
          },
        )
        signupdetails = (
          <div>
            <p>
              Congratulations on subscribing to an EdgeForecaster{' '}
              <span className="spanhighlight">{planname}</span>. You will be
              sent an email shortly with a link to verify your email address and
              activate your account. Once you have done this, click on the{' '}
              <span className="spanhighlight">Sign In</span> button on the menu
              to log into your profile.
            </p>
            <p>
              If you don’t receive an email, please click{' '}
              <span className="spanhighlight point" onClick={this.resendemail}>
                here
              </span>{' '}
              to resend it. 
            </p>
          </div>
        )
      }
    }
    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                {signupdetails}
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(SignUpDetailsPage)
export const SignUpDetailsPageQuery = graphql`
  query SignUpDetailsPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
